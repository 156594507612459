













































import HealthProfessionFieldForm from '@/components/healthProfessionField/HealthProfessionFieldForm.vue';
import HealthProfessionFieldUtils, {
  HealthProfessionField,
} from '@/utils/HealthProfessionFieldUtils';
import Vue from 'vue';

export default Vue.extend({
  components: { HealthProfessionFieldForm },

  data(): {
    state: {
      valid: boolean;
      submit: boolean;
      delete: boolean;
      errors?: any[];
    };
    item?: Partial<HealthProfessionField>;
  } {
    return {
      state: {
        valid: false,
        submit: false,
        delete: false,
        errors: undefined,
      },
      item: undefined,
    };
  },

  async mounted() {
    try {
      const item = await HealthProfessionFieldUtils.api.get(
        parseInt(this.$route.params?.id)
      );
      this.item =
        HealthProfessionFieldUtils.healthProfessionField.generateDefault(item);
      (this.$refs.form as any).resetValidation();
    } catch (error) {
      console.error(error);
      if (error.response.status === 404) {
        // TODO: not found
      }
    }
  },

  methods: {
    async handleSubmit() {
      if (this.item) {
        try {
          this.state.errors = undefined;
          this.state.submit = true;
          (this.$refs.form as any).validate();
          this.item = await HealthProfessionFieldUtils.api.update(this.item);
          (this.$refs.form as any).resetValidation();
          this.$toast.push({
            text: this.$t('success.edit', [
              this.$tc('healthProfessionField.label'),
            ]),
            type: 'success',
          });
        } catch (error) {
          this.$toast.push({
            text: this.$t('error.edit', [
              this.$tc('healthProfessionField.label'),
            ]),
            type: 'error',
          });
          this.state.errors = error; // TODO: handle errors
        } finally {
          this.state.submit = false;
        }
      }
    },

    async handleDelete() {
      try {
        this.state.errors = undefined;
        this.state.delete = true;
        await HealthProfessionFieldUtils.api.remove(
          parseInt(this.$route.params?.id)
        );
        this.$toast.push({
          text: this.$t('success.delete', [
            this.$tc('healthProfessionField.label'),
          ]),
          type: 'success',
        });
        this.$router.replace({ name: 'health-profession-field-list' });
      } catch (error) {
        this.$toast.push({
          text: this.$t('error.delete', [
            this.$tc('healthProfessionField.label'),
          ]),
          type: 'error',
        });
        this.state.errors = error; // TODO: handle errors
      } finally {
        this.state.delete = false;
      }
    },
  },
});
